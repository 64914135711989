<template>
  <v-card class="p-1 m-0 my-2 px-2 pe-0" :color="hexTints(area.cor,0.92)">
    <v-container fluid class="m-0 p-0">
      <v-row no-gutters align="center">
        <v-col cols="11">
          <v-card flat class="m-0 p-0 fs-10pt text-justify" @click="$emit('selConteudo',item)" :color="hexTints(area.cor,0.92)">
            <v-tooltip bottom color="white" :key="refresh" open-delay="1000">
              <template v-slot:activator="{ on, attrs }" class="">
                <v-card v-bind="attrs" v-on="on" class="m-0 p-1 px-1 line-height-3" flat :color="hexTints(area.cor,0.98)">
                  <span class="border px-1" :style="'background-color:'+hexTints(area.cor,0.85)">
                    <span class="fs-8pt fw-800">ID:</span>
                    <span class="ms-1 fs-8pt fw-600 f-lato">{{item.id}}</span>
                  </span>
                  {{item.texto}}
                </v-card>
              </template>
              <v-card class="p-1" v-if="ucs != null">
                <p class="m-0 p-1 mb-2 line-height-1" :style="'background-color:'+hexTints(area.cor,0.87)">
                  <span class="ms-1 fs-8pt fw-400">Conteúdo</span>
                  <span class="ms-1 fs-8pt fw-800">ID:</span>
                  <span class="ms-1 fs-8pt fw-600 f-lato">{{item.id}}</span>
                  <span class="ms-1 fs-8pt fw-400">indicado em:</span>
                </p>
                <div class="px-1">
                  <uclabel :ucID="item.id" v-for="(item,key,index) in ucs" :key="key+refresh"/>
                </div>
              </v-card>
            </v-tooltip>
          </v-card>
        </v-col>
        <v-col cols="auto" class="ps-1 text-right">
          <i v-i:ic#check-circle#24 v-cHex="'#10c181'" class="btn-icon-left ps-1"></i>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "conteudoitem",
  components: {
    'uclabel': () => import('./uc-label.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
    item: { default: "", type: Object },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      ucs: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
      rdb.ref("/curriculo/conteudos/conteudos/"+this.item.id+"/ucs").get().then(function(snap) {
        self.ucs = snap.val();
        self.refresh++;
        console.log("self.ucs",self.ucs);
      });
    }
  }
}
</script>

<style scoped>
</style>
